
























































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import { TypedComponentProps } from "@/vue-props-types"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import DefaultButton from "@/components/buttons/DefaultButton.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import WithBackPageLayout from "@/layouts/WithBackPageLayout.vue"
import { Props } from "./types"
import Card from "@/components/cards/Card.vue"
import TextField from "@/components/textFields/TextField.vue"
import TableHeader from "@/utilities/TableHeader"
import DateTime from "@/models/DateTime"
import { goToRoute } from "@/router"
import RouteName from "@/constants/RouteName"
import IconButton from "@/components/buttons/IconButton.vue"
import CalendarMenu from "@/components/pickDateTime/CalendarMenu.vue"
import Dropdown, { Option } from "@/components/dropdowns/Dropdown.vue"
import ApproveDialog from "./ApproveDialog.vue"
import { workflowContainer } from "@/containers/WorkflowContainer"
import TeamsContext from "@/models/TeamsContext"
import { WorkflowIn, workflowStatusText } from "@/models/Workflow"

export interface ApproveData {
  id: string
  name: string
  operation: number
  comment: string
}
export interface TableData {
  workflowId: string
  isApprovable: boolean
  groupId: string
  channelId: string
  requestDate: DateTime
  requestDateStr: string
  applicant: string
  applicantId: string
  statusNum: number
  status: string
  meetingId: string
  meetingStructureId: string
  filePath: string
  comment: string
  approvers: Array<ApproveData>
}

interface State {
  loading: boolean
  status: number
  tableItems: Array<TableData>
  tableItemsAll: Array<TableData>
  startDate: Date
  endDate: Date
  allUsers: { [key: string]: string | null }
  selectedItem: TableData | null
}

const propsOptions = {
  id: {
    type: String,
    required: true,
  },
}

export default defineComponent<TypedComponentProps<Props, typeof propsOptions>>(
  {
    props: propsOptions,
    components: {
      I18nFormattedMessage,
      DefaultButton,
      WithBackPageLayout,
      Card,
      TextField,
      IconButton,
      CalendarMenu,
      Dropdown,
      ApproveDialog,
    },
    setup(props) {
      const tableHeaders = computed<Array<TableHeader>>(() => [
        { text: "起案日時", value: "requestDateStr" },
        { text: "起案者", value: "applicant" },
        { text: "ステータス", value: "status" },
        { text: "会議", value: "meeting" },
        { text: "", value: "action" },
      ])
      const statusItems = computed<Array<Option>>(() => [
        { text: "", value: -1 },
        { text: "処理中", value: 0 },
        { text: "承認", value: 1 },
        { text: "却下", value: 2 },
      ])

      const state = reactive<State>({
        status: -1,
        loading: true,
        tableItems: [],
        tableItemsAll: [],
        allUsers: {},
        startDate: new DateTime(new Date()).add({ months: -1 }).toJsDate(),
        endDate: new DateTime(new Date()).toJsDate(),
        selectedItem: null,
      })

      const { getContext, getUser } = teamsContextContainer.useContainer()
      const { getWorkflowsAsync } = workflowContainer.useContainer()

      const sort = () => {
        const start = new Date(new DateTime(state.startDate).toDateString())
        const end = new Date(
          new DateTime(state.endDate).add({ days: 1 }).toDateString()
        )
        state.tableItems = state.tableItemsAll.filter(
          a =>
            a.requestDate.isSameOrAfter(start, "minutes") &&
            a.requestDate.isBefore(end, "minutes") &&
            (state.status === -1 || state.status === a.statusNum)
        )
      }

      const setStartDate = (start: string) => {
        state.startDate = new Date(start)
        sort()
      }

      const setEndDate = (end: string) => {
        state.endDate = new Date(end)
        sort()
      }

      const dropdownChange = (value: number) => {
        state.status = value
        sort()
      }

      const getAllUserName = async () => {
        const users = await Promise.all(
          Object.keys(state.allUsers).map(currentKey => getUser(currentKey))
        )
        for (const user of users) {
          state.allUsers[user?.id || ""] = user?.displayName || ""
        }
        state.tableItems = state.tableItems.map(t =>
          Object.assign(t, {
            applicant: state.allUsers[t.applicantId],
            approvers: t.approvers.map(a =>
              Object.assign(a, { name: state.allUsers[a.id] })
            ),
          })
        )
      }

      const convertWorkflow = (
        context: TeamsContext,
        item: WorkflowIn
      ): TableData => {
        const isApprover = item.approvers.find(
          a => a.userId == context.userObjectId
        )
        return {
          workflowId: item.id,
          comment: item.applicantComment,
          groupId: item.groupId,
          channelId: item.channelId,
          requestDate: item.createdAt,
          requestDateStr: item.createdAt.toDateStringSlashFull(),
          isApprovable:
            !!isApprover && isApprover.operation === 0 && item.status === 0,
          applicant: "...",
          applicantId: item.applicantId,
          statusNum: item.status,
          status: workflowStatusText[item.status],
          meetingId: item.meetingId,
          meetingStructureId: item.meetingStructureId,
          filePath: item.filePath,
          approvers: item.approvers.map(ap => {
            return {
              id: ap.userId,
              name: "",
              operation: ap.operation,
              comment: ap.comment,
            } as ApproveData
          }),
        }
      }

      const init = async () => {
        state.loading = true
        const context = await getContext()
        const items = await getWorkflowsAsync(false, props.id)
        items.forEach(item => {
          state.allUsers[item.applicantId] = null
          item.approvers.forEach(ap => {
            state.allUsers[ap.userId] = null
          })
        })
        state.tableItemsAll = items.map(item => convertWorkflow(context, item))
        sort()
        state.loading = false
        await getAllUserName()
      }

      watch(
        () => props.id,
        async _ => {
          await init()
        }
      )

      onMounted(async () => {
        await init()
      })

      const openMeeting = (item: TableData) => {
        goToRoute({
          name: RouteName.InMeeting,
          params: { parentId: props.id, id: item.meetingId },
        })
      }

      const onMessageBtnClick = (item: TableData) => {
        state.selectedItem = item
      }

      const onRowDownloadBtnClick = (item: TableData) => {
        window.open(item.filePath)
      }

      return {
        state,
        tableHeaders,
        statusItems,
        setStartDate,
        setEndDate,
        dropdownChange,
        openMeeting,
        onMessageBtnClick,
        onRowDownloadBtnClick,
      }
    },
  }
)
