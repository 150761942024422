



















































import {
  defineComponent,
  reactive,
  PropType,
  watch,
} from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import TextArea from "@/components/textAreas/TextArea.vue"
import { TableData } from "./ConfirmApproves.vue"
import { workflowOperationText } from "@/models/Workflow"

interface State {
  isLoading: boolean
  item: TableData
}

export default defineComponent({
  props: {
    tableItem: {
      type: Object as PropType<TableData>,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    I18nFormattedMessage,
    DefaultDialog,
    TextArea,
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      isLoading: false,
      item: {} as TableData,
    })

    const onCancel = () => {
      emit("close")
    }

    watch(
      () => props.visible,
      visible => {
        if (visible) {
          state.item = Object.assign({}, props.tableItem)
        }
      }
    )

    watch(
      () => props.tableItem,
      visible => {
        if (visible) {
          state.item = Object.assign({}, props.tableItem)
        }
      },
      { deep: true }
    )

    return {
      state,
      onCancel,
      workflowOperationText,
    }
  },
})
